<script>
	import { mapMutations, mapActions } from 'vuex';
	
	export default {
		created() { // When the logout page is loaded, clear the auth details from the session storage to effectively log the user out, clear the cookie containing the hierarchy of customers that the user is managing, and redirect to the OAuth logout page to invalidate the server side-session and all access tokens.
			this.setUserReady(false); // Hides the user interface so that there isn't a flash to the customer selection mode when the hierarchy of customers that the user is cleared.
			this.clearAccessToken();
			this.clearManagingCustomers();
			this.notifyLogout(); // Notifies any other open tabs of the logout.
			this.redirectToLogout();
		},
		methods: {
			notifyLogout() { // Temporarily sets an item in the local storage, which has the effect of notifying any other open tabs of the logout.
				localStorage.setItem('LOGGING_OUT', Date.now().toString());
				localStorage.removeItem('LOGGING_OUT');
			},
			redirectToLogout() { // Redirect the browser to the server-side logout page.
				// Set the Base URL of the server-side logout page.
				let logoutUrl = `${process.env.VUE_APP_AUTH_URL}logout`;
				
				// If there is a Redirect URL set in the query string, add it to the query string of the logout URL.
				const queryString = new URLSearchParams(window.location.search);
				const redirectUrl = queryString.get('redirect_url');
				if(redirectUrl !== null) {
					logoutUrl += `?redirect_url=${encodeURIComponent(redirectUrl)}`;
				}
				
				// Redirect the browser to the generated URL.
				window.location.replace(logoutUrl);
			},
			...mapMutations([
				'clearManagingCustomers', 'setUserReady'
			]),
			...mapActions([
				'clearAccessToken'
			])
		}
	}
</script>